$(function () {
    var linkCards = {};

    linkCards.bind = function () {
        linkCards.cardImg.off('click.img').on('click.img', function () {
            linkCards.checkState($(this));
        });
    };

    linkCards.checkState = function (image) {
        if (image.hasClass(linkCards.activeClass)) {
            image.removeClass(linkCards.activeClass);
        } else {
            image.addClass(linkCards.activeClass);
        };
    };

    linkCards.init = function () {
        linkCards.cardImg = $('.c-link-cards__image');
        linkCards.activeClass = 'c-link-cards__image--active';
        linkCards.bind();
    }();
});


$(function () {
    var rtCookie = {
        cName: "cookieMsg"
    };


    rtCookie.setCookie = function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    rtCookie.getCookie = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    rtCookie.bindCtrls = function () {
        $('#AcceptCookiePolicy').off('click').on('click', function (e) {
            e.preventDefault();
            rtCookie.hideBanner();
        });
    };

    rtCookie.hideBanner = function () {
        $('#CookiePolicy').addClass('hidden');
    };

    rtCookie.addCookieMarkup = function () {
        var html = '<div id="CookiePolicy"><div class="text"><p>To give you the best possible experience, this site uses cookies. If you continue to browse our website we will assume that you are happy to receive cookies.</p></div><div class="buttons"><a class="button" id="AcceptCookiePolicy" href="">Ok</a><a class="button button-secondary" href="/website-policies.aspx">More details</a></div></div>';

        $('body').prepend(html);
    };

    rtCookie.checkCookie = function () {
        var cookieStored = rtCookie.getCookie(rtCookie.cName);
        if (cookieStored == "") {
            rtCookie.addCookieMarkup();
            rtCookie.setCookie(rtCookie.cName, true, 365);
            rtCookie.bindCtrls();
        };
    }();

});